@font-face {
  font-family: "thin";
  src: url("./fonts/ProductSans/ProductSans-Thin.ttf") format("tff");
}

@font-face {
  font-family: "bold";
  src: url("./fonts/ProductSans/ProductSans-Bold.ttf") format("ttf");
}
@font-face {
  font-family: "italic";
  src: url("./fonts/ProductSans/ProductSans-Italic.ttf") format("ttf");
}
@font-face {
  font-family: "boldIt";
  src: url("./fonts/ProductSans/ProductSans-BoldItalic.ttf") format("ttf");
}
@font-face {
  font-family: "blak";
  src: url("./fonts/ProductSans/ProductSans-Black.ttf") format("ttf");
}
@font-face {
  font-family: "medium";
  src: url("./fonts/ProductSans/ProductSans-Medium.ttf") format("ttf");
}
@font-face {
  font-family: "light";
  src: url("./fonts/ProductSans/ProductSans-Light.ttf") format("ttf");
}
@font-face {
  font-family: "regular";
  src: url("./fonts/ProductSans/ProductSans-Regular.ttf") format("ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "bold";
}

nav a.active {
  color: #6c4cdf;
  transition: 0.5s ease-in-out;
  border-bottom: 2px solid #6c4cdf;
  padding-bottom: 5px;
}
